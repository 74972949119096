.noScreen {
    font-family: Times New Roman;
}

.print-header {
    margin-bottom: 5px;
    background-color: #e2e3e5;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 1%;
    padding: 8px;
}

.header-props-top {
    font-size: 18px;
}

.header-props-others {
    font-size: 14px;
}

.table thead {
    font-size: 12px;
}

.table td {
    font-size: 12px;
}

.timestamp {
    font-size: 10px;
    margin-top: 1rem;
}

.loading-analysis thead, td {
    font-size: 10px;
}