body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(244, 242, 238);
  height: 100%;
  overflow: auto;
}

.btn-primary {
  background-color: #1b4d89;
  border-color: #1b4d89;
}

.theme-dark-color {
  background-color: #3B3F46;
  color: #fff;
}

.theme-dark-button {
  color: #1F4762;
}