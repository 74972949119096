.login-form{
  padding: 1.5rem;
  width: 300px;
  border: 1px solid rgb(250, 188, 3);
  opacity: 1;
  background: #1111109d;
  border-radius: 2%;
}

.login-form .form-label{
  color: #FFFF;
}

.login-form .btn-link{
  text-decoration: none;
}

.login-error-message{
  color: red;
  padding: 0.2rem 0rem
}

@media (max-width: 500px) {
  .login-form{
    margin-top: 1rem;
    padding: 1rem;
  }
}