.d-flex-jcs{
  display: flex;
  justify-content: flex-start;
}

.d-flex-jcb{
  display: flex;
  justify-content: space-between;
}

.d-flex-jcb hr{
  visibility: hidden;
}

.filter-set{
  max-height: 16rem;
  overflow: hidden;
  overflow-y: scroll;
}

.filter-set-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 0.6rem;
  margin-bottom: 1px;
}

.filters{
  width: 25%;
}

.filter-set-item{
  margin: 1px 0;
}

.filter-section-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem;
}

.filter-section-title .btn-link{
  text-decoration: none;
}

.results-list{
  width: 75%;
}

.filters, .results-list{
  padding: 0.2rem;
}

.card-top{
  background-color: aliceblue;
}

@media (max-width: 800px) {
  .d-flex-jcs{
    display: flex;
    flex-direction: column;
  }

  .d-flex-jcb{
    display: flex;
    flex-direction: column;
  }

  .field-heading{
    margin-top: 0.5rem;
  }

  .filters{
    margin-bottom: 1rem;
  }
  .filters, .results-list{
    width: 100%;
    padding: 0;
  }
}