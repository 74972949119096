.admin-portal-container{
    display: flex;
}

.user-groups-card{
    margin: 0.5rem;
}

.search-results-card{
    /* display: flex; */
    /* justify-content: space-between; */
    border: 1px solid lightgrey;
    padding: 0.5rem;
    margin: 0.5rem 0;
}

.admin-portal-menu{
    cursor: pointer;
}

/* .results-container{
    max-height: 30rem;
    overflow-y: scroll;
} */

@media (max-width: 800px) {
    .search-results-card .btn-primary{
        width: 100%;
    }
  }