.forgot-email-form{
  padding: 1.5rem;
  width: 600px;
  border: 1px solid rgb(250, 188, 3);
  opacity: 1;
  background: #1111109d;
  border-radius: 2%;
  margin-top: 10rem;
}

.forgot-email-form .form-label{
  color: #FFFF;
}

.forgot-email-form .btn-link{
  text-decoration: none;
}

.forgot-email-form .error-message{
  color: red;
  padding: 0.2rem 0rem
}

@media (max-width: 500px) {
  .forgot-email-form{
    margin: 5rem 1.5rem 0 1.5rem;
    padding: 1rem;
    width: 100%;
  }
}