
.home-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 8rem;
}

.home-content{
    width: 50%;
    margin: 0 auto;
}

.home-app-name {
    font-size:3vw;
    font-weight: 600;
    margin-bottom: 0;
    color: rgb(224, 173, 3);
    text-shadow:
    0.02em 0 black,
    0 0.02em black,
    -0.02em 0 black,
    0 -0.02em black;
}

.home-app-subname {
    font-size:2vw;
    margin: 0.2rem;
    font-weight: 500;
    color: rgb(224, 173, 3);
    text-shadow:
    0.02em 0 black,
    0 0.02em black,
    -0.02em 0 black,
    0 -0.02em black;
}

.home-horizontal-separator {
    border-bottom: 5px solid #ffc107;
    width: 100%;
    margin-top: 0.1rem;
    margin-bottom: 0.8rem;
}

@media (max-width: 800px) {
    .home-content {
        width: 100%;
        margin: 0 auto;
    }

    .home-app-name {
        font-size: 1.5rem;
        padding-bottom: 0;
        margin-top: 1.5rem;
        -webkit-text-stroke: 0.5px black;
    }

    .home-app-subname {
        font-size:5vw;;
        font-weight: 500;
        color: rgb(224, 173, 3);
        -webkit-text-stroke: 0.5px black;
        margin: 0;
        padding: 0;
    }

    .home-container {
        margin-top: 0;
        margin-bottom: 5rem;
    }

    .home-horizontal-separator {
        margin-bottom: 0.2rem;
    }
}
