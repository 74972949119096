.d-flex-jcs{
  display: flex;
  justify-content: flex-start;
}

.d-flex-jcb{
  display: flex;
  justify-content: space-between;
}


@media (max-width: 800px) {
  .d-flex-jcs{
    display: flex;
    flex-direction: column;
  }

  .d-flex-jcb{
    display: flex;
    flex-direction: column;
  }
}