.navbar-brand {
    font-weight: bolder;
    font-size: 2rem;
}

.nav-elements {
    display: flex;
    justify-content: left;
    flex-direction: row;
}

.btn-link {
    text-decoration: none;
    font-size: large;
}

.logo {
    background-image: url("../assets/images/logo.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 80px;
    width: 100px;
}

@media (max-width: 800px) {
    .nav-elements {
        display: flex;
        justify-content: left;
        align-items: stretch;
    }

    .navbar-dark .btn-md {
        width: 95%;
        margin: 0.2rem 0.5rem;
    }

    .logo {
        height: 60px;
        width: 60px;
    }
}