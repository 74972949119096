.d-flex-jcs{
  display: flex;
  justify-content: flex-start;
}

.d-flex-jcb{
  display: flex;
  justify-content: space-between;
}
.d-flex-jce{
  display: flex;
  justify-content: space-evenly;
}
.d-flex-jcend{
  display: flex;
  justify-content: flex-end;
}

.delivery-order-modal-error{
  font-size: small;
  text-align: center;
}

.page-header-button-container{
  display: flex;
  justify-content: flex-end;
}

.do-table-cell{
  vertical-align: middle;
  padding: 0;
}


@media (max-width: 800px) {
  .d-flex-jcs{
    display: flex;
    flex-direction: column;
  }

  .d-flex-jcb{
    display: flex;
    flex-direction: column;
  }

  .d-flex-jce{
    display: flex;
    flex-direction: column;
  }


}