.app-container {
  background-color: #fff;
  min-height: 85vh;
  margin-top: 2%;
  padding: 2rem;
  border-radius: 1%;
}

.home-bg {
  background-image: url("./Shared/assets/images/homebgimageenhenced.JPG");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  overflow: auto;
}

.d-flex-jcb,
.d-flex-jcs {
  display: flex;
  flex-wrap: wrap;
}

.d-flex-jcb {
  justify-content: space-between;
}

.d-flex-jcs {
  justify-content: flex-start;
}

.col {
  margin: 0 0.3rem;
}

.form-section-heading {
  font-size: 1.2rem;
  margin-top: 1.1rem;
  margin-bottom: 0.5rem;
  background-color: lightgray;
  padding: 0.3rem;
  font-weight: bold;
}

.history-items-container,
.document-items-container {
  max-height: 20rem;
  overflow-y: scroll;
}

.document-action-button {
  margin: 0 0.4rem;
  font-weight: bold;
}

.page-heading {
  margin-bottom: 0.5rem;
}

.table-first-column {
  text-align: right;
}

.add-button {
  max-height: 40px;
  min-width: 62px;
}

.trucated-table-cell {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.entity-detail .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selected-option-row {
  background: #c9dff5;
}

.error-option-row {
  background: #f5c9c9;
}

.entity-list-items {
  max-height: 45rem;
  overflow-y: scroll;
}

.pagination-buttons .btn:active {
  outline: none;
}

.table-cell-right {
  text-align: right;
}

.data-table {
  max-height: 2rem;
  overflow: hidden;
  overflow-y: scroll;
}

.history-comment-section {
  display: flex;
  justify-content: flex-start;
}

.history-comment-section .history-contents,
.comment-contents {
  width: 50%;
}

.comment-contents .btn-link {
  text-decoration: none;
  font-weight: bold;
}

.page-action-button-group {
  display: flex;
  justify-content: flex-start;
  margin-top: 2rem;
}

.table-layout {
  table-layout: fixed;
  width: 5rem;
}

.navigation-button {
  margin: 0.2rem;
}

@media (max-width: 800px) {

  .d-flex-jcb,
  .d-flex-jcs {
    flex-direction: column;
  }

  .page-heading {
    margin-bottom: 0.2rem;
  }

  .app-container {
    background-color: #fff;
    min-height: 85vh;
    margin-top: 2%;
    padding: 1rem;
  }

  .document-action-button {
    margin: 0.8rem 0.2rem;
  }

  .entity-detail {
    margin-top: 0.8rem;
    transition: display 3s;
  }

  .entity-detail td {
    font-size: 12px;
  }

  .entity-content .col {
    margin: 0.3rem 0;
  }

  .document-title-input {
    margin-bottom: 0.5rem;
  }

  .entity-list-items {
    max-height: 30rem;
  }

  .entity-list-items .card-title {
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 0.9rem;
  }

  .history-comment-section .history-contents,
  .comment-contents {
    width: 100%;
  }

  .history-comment-section {
    flex-direction: column;
  }

  .page-action-button-group {
    flex-direction: column;
    margin-top: 1rem;
  }

  .navigation-button {
    margin: 0.2rem 0;
  }
}

.App-link {
  color: #61dafb;
}

/* .scrollable-table-body{
  max-height: 2px;
  overflow-y: scroll;
  overflow: hidden;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
} */

@media screen {
  .noScreen {
    display: none !important;
  }
}

@media print {
  .noPrint {
    display: none !important;
  }

  @page {
    margin: 1cm;
  }

  body {
    font-family: Arial, sans-serif;
    font-size: 12pt;
    line-height: 1.5;
    margin: 0;
    padding: 0;
    background-color: #fff;
  }

  .page-break {
    page-break-before: always;
  }
  
  .noScreen {
    padding: 20px;
    page-break-inside: avoid;
  }
}


.form-title {
  font-size: 1.5rem;
  border-bottom: 1px solid darkgrey;
  margin-bottom: 1rem;
  font-weight: bold;
}

hr {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.required-span {
  color: red;
}

span {
  content: "\002A";
}

.fixed-width{
  width: 50px;
}

.create-dos-transfers-list{
  font-size: 12px;
}

.create-dos-transfers-list .form-control, .form-select{
  font-size: 12px !important;
}

.react-datepicker-wrapper{
  width: 100%;
}

.reporting-container td{
  font-size: 14px;
}

.reporting-container .fixed-width{
  width: 1%;
  font-weight: normal;
}

.reporting-container Table thead{
  position: sticky;
  top: 0px;
  margin: 0 0 0 0;
}

.report-table-container{
  overflow: scroll;
}