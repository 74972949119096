.order-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.step {
   width:76rem;
   padding: 0.6rem;
   margin: 0.3rem;
   font-weight: bold;
   text-align: center;
   color: #212529;
   font-size: medium;
   border: 3px solid #ffc107;
}

.current-step {
  background-color: #ffc107;
  border: 3px solid #212529;
}

@media (max-width: 800px) {
  .order-progress {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }

  .step {
    width:100%;
    padding: 0.2rem;
    margin: 0.2rem;
    text-align: center;
    font-size: small;
 }

}