.dashboard{
  display: flex;
  justify-content: flex-start;
}

.dashboard-menu, .contents-section{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  align-items: flex-start;
  margin-right: 10px;
  padding: 0;
}

.dashboard .section-heading{
  padding: 10px;
  width: 100%;
  margin: 3px 0;
}

.dashboard .btn-warning {
    width: 100%;
    margin: 0.2rem 0; 
    text-align: left;
    font-weight: bold;
}

.section-elements{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.section-elements .count {
  width: 176px;
  margin: 0.3rem;
  text-align: center;
  border: 3px solid #ffc107;
  background-color: aliceblue;
}

.dashboard .menu-icon{
  margin-right: 10px;
}

.dashboard .loading{
  margin: 1rem 0;
}

@media (max-width: 800px) {
  .dashboard{
    flex-direction: column;
  }

  .dashboard .btn-warning {
    width: 100%;
    margin: 0.2rem 0; 
   }

   .section-elements .count {
    width: 100%;
    margin: 0.2rem;
  }
}
